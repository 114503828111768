/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"
import { languages } from "../utils/content/menuContent"

function SimpleSEO({ description, title, image, themeColor = "#FFFAF6", locale, noindex }) {
  const location = useLocation()
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            image
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const lang = locale
  const path = location ? location.pathname.split("/" + lang + "/")[1] : ""

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | TIER`}
    >
      {/* <!-- BEGIN Usercentrics related code --> */}
      <link rel="preconnect" href="//app.usercentrics.eu" />
      <link rel="preconnect" href="//api.usercentrics.eu" />
      <link rel="preconnect" href="//privacy-proxy.usercentrics.eu" />
      <link rel="preload" href="//app.usercentrics.eu/browser-ui/latest/loader.js" as="script" />
      <link rel="preload" href="//privacy-proxy.usercentrics.eu/latest/uc-block.bundle.js" as="script" />
      <script id="usercentrics-cmp" data-settings-id="YXWrnanuq" src="https://app.usercentrics.eu/browser-ui/latest/loader.js" async></script>
      <script type="application/javascript" src="https://privacy-proxy.usercentrics.eu/latest/uc-block.bundle.js"></script>

      {noindex && <meta name="robots" content="noindex" />}

      <meta name="theme-color" content={themeColor} />
      <meta name="description" content={metaDescription} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={location ? location.origin + "/" + locale + "/" + path : ""} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={site.siteMetadata.author} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={image} />
      <link rel="alternate" href={location ? location.origin + "/" + "en" + path : ""} hrefLang="x-default" key={"x-default"} />
      <link
        rel="alternate"
        href={location ? location.origin + "/" + languages[0].code + path : ""}
        hrefLang={languages[0].code}
        key={languages[0].code}
      />
      <link
        rel="alternate"
        href={location ? location.origin + "/" + languages[1].code + path : ""}
        hrefLang={languages[1].code}
        key={languages[1].code}
      />
      <link
        rel="alternate"
        href={location ? location.origin + "/" + languages[2].code + path : ""}
        hrefLang={languages[2].code}
        key={languages[2].code}
      />
      <link
        rel="alternate"
        href={location ? location.origin + "/" + languages[3].code + path : ""}
        hrefLang={languages[3].code}
        key={languages[3].code}
      />
      <link
        rel="alternate"
        href={location ? location.origin + "/" + languages[4].code + path : ""}
        hrefLang={languages[4].code}
        key={languages[4].code}
      />
      <link
        rel="alternate"
        href={location ? location.origin + "/" + languages[5].code + path : ""}
        hrefLang={languages[5].code}
        key={languages[5].code}
      />
      <link
        rel="alternate"
        href={location ? location.origin + "/" + languages[6].code + path : ""}
        hrefLang={languages[6].code}
        key={languages[6].code}
      />
      <link
        rel="alternate"
        href={location ? location.origin + "/" + languages[7].code + path : ""}
        hrefLang={languages[7].code}
        key={languages[7].code}
      />
      <link
        rel="alternate"
        href={location ? location.origin + "/" + languages[8].code + path : ""}
        hrefLang={languages[8].code}
        key={languages[8].code}
      />
      <link
        rel="alternate"
        href={location ? location.origin + "/" + languages[9].code + path : ""}
        hrefLang={languages[9].code}
        key={languages[9].code}
      />
      <link
        rel="alternate"
        href={location ? location.origin + "/" + languages[10].code + path : ""}
        hrefLang={languages[10].code}
        key={languages[10].code}
      />
      <link
        rel="alternate"
        href={location ? location.origin + "/" + languages[11].code + path : ""}
        hrefLang={languages[11].code}
        key={languages[11].code}
      />
      <link
        rel="alternate"
        href={location ? location.origin + "/" + languages[12].code + path : ""}
        hrefLang={languages[12].code}
        key={languages[12].code}
      />
      <link
        rel="alternate"
        href={location ? location.origin + "/" + languages[13].code + path : ""}
        hrefLang={languages[13].code}
        key={languages[13].code}
      />
    </Helmet>
  )
}

SimpleSEO.defaultProps = {
  description: ``,
  image: "",
  locale: "en",
  themeColor: "#FBFBFB",
}

SimpleSEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string.isRequired,
  location: PropTypes.any,
  themeColor: PropTypes.string,
  locale: PropTypes.string,
  noindex: PropTypes.bool,
}

export default SimpleSEO
