import React, { useEffect } from "react"
import PropTypes from "prop-types"

// Components
import Seomatic from "../../components/Seomatic"

// Helpers & mock content
import { fireEvent } from "../../utils/helpers"
import { RenderComponent } from "../../utils/componentRenderer"
import SimpleSEO from "../../components/SimpleSEO"
import SEO from "../../components/SEO"

// markup
const PageBuilder = ({ pageContext }) => {
  const { pageInfo, locale, slug } = pageContext
  const { seomatic, modules, navbarTheme } = pageInfo

  useEffect(() => {
    fireEvent("nav-scheme", navbarTheme)
  }, [])

  return (
    <div className="relative">
      <SEO seomatic={seomatic} path={locale + "/" + (slug === "__home__" ? "" : slug)} />
      {modules.map((module, i) => RenderComponent(module, i))}
    </div>
  )
}

PageBuilder.propTypes = {
  pageContext: PropTypes.shape({
    pageInfo: PropTypes.object,
    footerInfo: PropTypes.object,
    locale: PropTypes.string,
    slug: PropTypes.string,
  }),
}

export default PageBuilder
